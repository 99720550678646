<template>
  <section class="data-list-container">
    <vx-card class="mb-4">

      <v-row>
        <v-col>
          <div class="d-flex items-center">
            <span class="mr-3">Imagine Background</span>
          </div>
          <div v-if="bannerData.background_image" class="d-flex items-center">
            <img :src="bannerData.background_image.src" alt="" width="200"/>
          </div>
          <image-uploader v-model="bannerData.background_image" labelIdle="Incarca imagine background..." />
          <error-messages :errors="errors.background_image" class="mt-1"></error-messages>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="10">
          <div class="">
            <v-text-field
              v-model="bannerData.title"
              class="mt-0"
              dense
              hide-details
              label="Titlu"
              outlined
            ></v-text-field>
          </div>
          <error-messages :errors="errors.title" class="mt-1"></error-messages>
        </v-col>
        <v-col cols="2" class="shrink color-picker-column" style="min-width: 220px;">
          <InlineColorPicker v-model="bannerData.title_color" />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="10">
          <div class="">
            <v-text-field
              v-model="bannerData.subtitle"
              class="mt-0"
              dense
              hide-details
              label="Subtitlu"
              outlined
            ></v-text-field>
          </div>
          <error-messages :errors="errors.subtitle" class="mt-1"></error-messages>
        </v-col>
        <v-col cols="2" class="shrink color-picker-column" style="min-width: 220px;">
          <InlineColorPicker v-model="bannerData.subtitle_color" />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="10">
          <div class="">
            <v-text-field
              v-model="bannerData.description"
              class="mt-0"
              dense
              hide-details
              label="Descriere"
              outlined
            ></v-text-field>
          </div>
          <error-messages :errors="errors.description" class="mt-1"></error-messages>
        </v-col>
        <v-col cols="2" class="shrink color-picker-column" style="min-width: 220px;">
          <InlineColorPicker v-model="bannerData.description_color" />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="10">
          <div class="d-flex items-center">
            <v-text-field
              v-model="bannerData.cta_label"
              class="mt-0"
              dense
              hide-details
              label="Buton Text"
              outlined
            ></v-text-field>
          </div>
          <error-messages :errors="errors.cta_label" class="mt-1"></error-messages>
        </v-col>
        <v-col cols="2" class="shrink color-picker-column" style="min-width: 220px;">
          <InlineColorPicker v-model="bannerData.cta_label_color" />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="10">
          <div class="d-flex items-center">
            <v-text-field
              v-model="bannerData.cta_link"
              class="mt-0"
              dense
              hide-details
              label="Buton Link"
              outlined
            ></v-text-field>
          </div>
          <error-messages :errors="errors.cta_link" class="mt-1"></error-messages>
        </v-col>
        <v-col cols="2" class="shrink color-picker-column" style="min-width: 220px;">
          <InlineColorPicker v-model="bannerData.cta_background_color" />
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <div class="d-flex items-center">
            <span class="mr-3">Imagine Produs</span>
          </div>
          <div v-if="bannerData.product_image_obj" class="d-flex items-center">
            <img :src="bannerData.product_image_obj.src" alt="" width="200"/>
          </div>
          <image-uploader v-model="bannerData.product_image" labelIdle="Incarca imagine produs..." />
          <error-messages :errors="errors.product_image" class="mt-1"></error-messages>
        </v-col>
      </v-row>

      <v-row>
        <v-col class="pt-5 justify-end">
          <v-btn
            color="primary"
            dark
            large
            @click="saveSettings"
          >
            Salveaza
          </v-btn>
        </v-col>
      </v-row>
    </vx-card>
  </section>
</template>

<script>
import ErrorMessages from '@/components/general-form/ErrorMessages'
import ImageUploader from '@/components/general-form/ImageUploader'
import InlineColorPicker from '@/views/Settings/BannerManagement/InlineColorPicker'
import router from '@/router'

export default {
  name: 'addBanner',
  components: {InlineColorPicker, ImageUploader, ErrorMessages},
  data () {
    return {
      bannerData: {
        background_image: '',

        title: '',
        title_color: '#00A4E1FF',

        subtitle: '',
        subtitle_color: '#F7F6F6FA',

        description: '',
        description_color: '#F7F6F6FA',

        product_image: '',

        cta_label: '',
        cta_label_color: '#F7F6F6FA',

        cta_link: '',
        cta_background_color: '#FCC129FF'
      },
      errors: {}
    }
  },
  methods: {
    saveSettings () {
      this.$http.post('/banners', this.bannerData)
        .then(() => {
          this.$store.dispatch('notify', {
            title: 'Succes!',
            text: 'Banner-ul a fost adaugat.',
            color: 'success'
          }, {root: true})
          router.push({path: '/settings/banner-management/'})
        })
        .catch(({response}) => {
          if (response.status === 422) {
            this.errors = response.data.errors
            this.$store.dispatch('notify', {
              title: 'Eroare!',
              text: 'Au fost gasite erori de validare pentru unul sau mai multe campuri!',
              color: 'danger'
            }, {root: true})
          } else {
            this.$store.dispatch('notify', {
              title: 'Eroare!',
              text: 'A aparut o eroare la incarcare. Va rugam reincarcati pagina sau incercati mai tarziu',
              color: 'danger'
            }, {root: true})
          }
        })
    }
  }
}
</script>

<style scoped lang="scss">

</style>
